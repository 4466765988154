.title {
  color: var(--heading-color);
  display: flex;
  align-items: center;
  text-align: start;
  max-width: 400px;
}

.title h2 {
  margin: 2rem;
}

.title::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--heading-color);
}

.tright h2 {
  margin: 2rem;
}

.tright {
  color: var(--heading-color);
  display: flex;
  align-items: center;
  text-align: start;
  width: 400px;
  margin-left: auto;
  margin-right: 2rem;
}

.tright::before {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--heading-color);
}

.about_wrapper {
  width: 100%;
  justify-content: space-between !important;
}

.about_text {
  width: 50%;
}

.photo_wrapper {
  position: relative;
  height: 54vh;
  width: calc(55vh * 0.7253);
}

.photo {
  position: absolute;
  top: 0;
  left: 0;
  height: 50vh;
  z-index: 2;
  border: 1px solid var(--highlight-color);
}

.photo_box {
  position: absolute;
  height: 50vh;
  width: calc(50vh * 0.7253);
  bottom: 0;
  right: 0;
  border: 1px solid var(--highlight-color);
  background-image: none;
}

.skills {
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
}

.skills li {
  list-style-type: none;
  line-height: 1.5rem;
}

@media (max-width: 768px) {
  .about_wrapper {
    flex-direction: column;
  }
  .about_text {
    width: 95%;
  }
  .photo_wrapper {
    margin: 2rem 0;
  }
}

@media (max-width: 580px) {
  .skills {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .title {
    justify-content: center;
  }
  .title::after {
    content: none;
  }
  .tright {
    justify-content: center;
    width: 100%;
  }
  .tright::before {
    content: none;
  }
}
