:root {
  --primary-color: #302ae6;
  --secondary-color: #536390;
  --font-color: #424242;
  --font-color-hover: #1f1e1e;
  --bg-color: #f5f5f5;
  --bg-color-rbg: 245, 245, 245;
  --box-shadow-color: #e8e6e6;
  --heading-color: #292922;
  --sidebar-color: #161625;
  --highlight-color: #e31b6d;
  --form-color: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

[data-theme="dark"] {
  --primary-color: #9a97f3;
  --secondary-color: #818cab;
  --font-color: #e1e1ff;
  --font-color-hover: #f8f8fa;
  --bg-color: #161625;
  --box-shadow-color: #130f29;
  --bg-color-rbg: 22, 22, 37;
  --heading-color: #818cab;
  --sidebar-color: #66bb6a;
  --form-color: #202031;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  background-color: var(--bg-color);
  color: var(--font-color);
}

.App {
  position: relative;
}
