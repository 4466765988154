.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.modal_content {
  background-color: #fff;
  width: 50%;
  height: 60vh;
  max-width: 700px;
  justify-content: space-between !important;
  overflow-y: auto;
}

.modal_content .modal_image {
  background-color: rgba(0, 0, 0, 0);
  background-position-x: center;
  background-position-y: center;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: cover;
  background-origin: padding-box;
  background-clip: border-box;

  width: 100%;
  height: 50%;
}

.modal_content .modal_context {
  padding: 2rem;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  height: 50%;
  color: #424242;
}

.modal_content .modal_context h1,
h2 {
  color: var(--highlight-color);
}

.modal .link_wrapper {
  width: 100%;
  justify-content: space-between;
}

.modal .links {
  flex-direction: row;
}

.modal .links a {
  padding: 0;
  font-size: 2rem;
  margin-right: 1.5rem;
  color: var(--highlight-color);
  text-decoration: none;
}

.modal .size {
  color: var(--highlight-color);
  font-size: 2rem;
}

.modal .size:hover {
  cursor: pointer;
}

.modal .links button {
  flex-direction: row;
}

.modal .links button svg {
  margin-right: 0.5rem;
}

@media (max-width: 489px) {
  .modal_content {
    width: 100%;
    height: 100vh;
  }
}
