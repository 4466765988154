.sidebar {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  display: flex;
}

.sidebar .icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar svg {
  margin: 0.5rem;
  font-size: 2rem;
  stroke: var(--sidebar-color);
}

.sidebar a {
  transition: transform 200ms ease-in-out;
}

.sidebar a:hover {
  transform: scale(1.2);
}

.sidebar .line {
  width: 0px;
  height: 100px;
  border: 1px solid var(--heading-color);
  margin: 3px;
  display: inline-block;
}

@media (max-width: 1450px) {
  .sidebar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3rem;
  }
  .sidebar .icons {
    margin: 0 auto;
    flex-direction: row;
  }
  .sidebar .line {
    border: none;
    height: 0px;
  }
}
