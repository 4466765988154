.copyright {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

@media (max-height: 600px) {
  .copyright {
    height: 20vh;
  }
}
