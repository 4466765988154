.intro {
  flex-direction: row !important;
  width: 60% !important;
  justify-content: flex-start !important;
}

.intro_text h1 {
  font-size: 5rem;
  margin: 1rem 0;
}

.intro_text h2 {
  font-size: 3rem;
  margin: 0;
}

.intro_text p {
  margin: 0;
  font-size: 1.4rem;
}

.typed {
  width: 50%;
  height: 100%;
}

#tsparticles {
  width: 50%;
  height: 95vh;
}

/* .tsparticles-canvas-el {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
} */

@media (max-width: 1150px) {
  .intro {
    width: 90% !important;
    justify-content: center !important;
  }

  /* @media (max-width: 768px) {
  .intro {
    width: 100%;
    justify-content: center !important;
    flex-direction: column !important;
  } */
  /* .typed {
    width: 100%;
    height: 30%;
  } */

  /* #tsparticles {
    width: 100%;
    height: 60vh;
  } */
}
@media (max-width: 460px) {
  .intro {
    margin-bottom: 0 !important;
  }
  .intro_text h1 {
    font-size: 4rem;
    margin: 1rem 0;
  }

  .intro_text h2 {
    font-size: 2rem;
    margin: 0;
  }

  .intro_text p {
    margin: 0;
    font-size: 1.1rem;
  }
}
