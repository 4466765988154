.nabar_wrapper {
  background-color: var(--bg-color);
  width: 100%;
  z-index: 200;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.5rem;
}

.sticky {
  position: sticky;
  top: 0;
}

.hamburger-react {
  display: none;
  font-size: 2rem;
  margin-left: 1rem;
}

.nav_links {
  background-color: rgba(var(--bg-color-rbg), 0.95) !important;
  flex-direction: row !important;
  list-style: none;
}

.nav_links li {
  margin-right: 2rem;
}

.nav_links a {
  position: relative;
}

.nav_link {
  text-decoration: none;
  color: var(--font-color);
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

.nav_links a::before {
  content: "";
  display: block;
  height: 3px;
  background: var(--highlight-color);
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  transform: scale(0, 1);
  transition: transform ease-in-out 250ms;
}

.nav_links a:hover::before {
  transform: scale(1, 1);
}

.nav_link:hover {
  color: var(--font-color-hover);
}

#nav_active {
  transform: translateY(0%);
}

.resume_btn {
  background-color: var(--bg-color);
  margin-left: 1rem;
}

.resume_btn a {
  text-decoration: none;
  color: var(--font-color);
  flex-direction: row;
  transition: all 0.3s ease-in-out;
}

.resume_btn:hover a {
  color: white;
}

@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .nabar_wrapper {
    box-shadow: 0 0px 10px var(--font-color);
  }
  .nav_links {
    margin: 0 0;
    padding: 1rem 0;
    font-size: 1.1rem;
    width: 100%;
    flex-direction: column !important;
    position: absolute;
    justify-content: space-around !important;
    right: 0px;
    min-height: minmax(25vh);
    background-color: var(--bg-color);
    top: 72.8333px;
    transform: translateY(-8vh) translateY(-2rem) translateY(-100%);
    transition: transform 0.3s ease-in;
  }
  .nav_links li {
    margin: 0;
  }
  .nav_links a::before {
    content: "";
    display: block;
    height: 3px;
    background: var(--highlight-color);
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform ease-in-out 250ms;
  }

  .nav_links a:hover::before {
    transform: scale(1, 1);
  }

  .hamburger-react {
    display: block;
    cursor: pointer;
  }
}
