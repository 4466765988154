.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-n {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1200px;
  width: 100%;
}

.full {
  min-height: 95vh;
  margin-bottom: 5rem;
}

button {
  background-color: #fff;
  font-size: 1.1rem;
  border: 2px solid var(--highlight-color);
  padding: 7px 14px;
  transition: all 0.3s ease-in-out;
}

button:hover {
  background-color: var(--highlight-color);
  color: white;
  cursor: pointer;
}

@media (max-width: 1236px) {
  .pd {
    padding: 0 1rem;
  }
}
