.project_container {
  flex-wrap: wrap;
  width: 100%;
}
.project_card_wrapper {
  flex: auto;
  min-width: 400px;
  max-width: 400px;
  height: 300px;
}

.project_card {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.project_backround {
  background-color: rgba(0, 0, 0, 0);
  background-position-x: center;
  background-position-y: center;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: cover;
  background-origin: padding-box;
  background-clip: border-box;

  transition: opacity 0.6s;

  width: 100%;
  height: 100%;
}

.project_text {
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  left: 0;
  top: 0;
}

.project_buttons {
  text-align: center;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.project_card:hover .project_backround {
  opacity: 0;
}

.project_card:hover .project_text {
  opacity: 1;
  top: 24%;
}

.project_card:hover .project_buttons {
  opacity: 1;
  bottom: 7%;
}

@media (max-width: 489px) {
  .project_card_wrapper {
    min-width: 100%;
    max-width: 100%;
  }
}
